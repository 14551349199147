@import '../../style/variables.scss';

.slick-slider {
  width: 100%;
  position: absolute !important;
  z-index: 0 !important;
}

.slick-list {
  @media (min-width: 1900px) {
    display: none !important;
  }
}
.ad {
    height: 335px;
    width: 500px;
    max-width: none;
    display: block;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;

    &-li {
      overflow: hidden;
      height: 100%;
      width: 100%;
      max-width: none;

      .video-js {
        height: 100vh;
        width: 100vw;
      }

      img,
      video {
        max-width: none;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .slick-slide > div {
    *:focus {
      outline: none !important;
    }

    @media (max-width: $xlm) {
      height: 420px;
    }

    @media (max-width: $md) {
      height: 440px;
    }


    @media (max-width: 540px) {
      height: 420px;
    }
    @media (max-width: 520px) {
      height: 445px;
    }

    @media (max-width: $xsm) {
      height: 398px;
    }
  }
