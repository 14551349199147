@import '../../style/variables.scss';

.publipostage-container {
  margin-top: 5rem;

  .container {
    margin-top: 70px;
    @media (max-width: $sm) {
      margin-top: 50px;
    }
    @media (max-width: 550px) {
      margin-top: 0px;
    }
    @media (max-width: $xsm) {
      margin-top: 0px;
    }
    .content-title {
      position: relative;
      width: fit-content;

      h3 {
        color: #00054c;
        font-size: 1.6rem;
        font-family: PoppinsBold;

        @media (max-width: $sm) {
          font-size: 1.4rem;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        @media (max-width: $xsm) {
          margin-bottom: 5px;
          margin-top: 5px;
          font-size: 1.1rem;
        }
      }

      .image-title {
        width: 30px;
        position: absolute;
        top: -7px;
        right: -20px;
      }
    }
  }
  .content-background {
    background-color: #e3f6ff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    @media (max-width: $xsm) {
      margin-top: 40px;
    }

    .row-1 {
      padding: 25px;
      position: relative;

      @media (max-width: $xsm) {
        padding: 10px;
      }
      .flocon2 {
        position: absolute;
        left: 50%;
        width: 50px;
      }
    }
  }
  .content-background2 {
    background-color: #e3f6ff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;

    @media (max-width: $xsm) {
      margin-bottom: 30px;
    }

    .flocon1 {
      position: absolute;
      width: 65px;
      left: -15px;
      bottom: 10px;
      z-index: 2;
    }
  }
  .content-publi {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #0d3a73;
    padding-top: 30px;
    margin-bottom: 30px;

    .content-image {
      position: relative;
      @media (max-width: $xsm) {
        text-align: center;
      }
      .letter-orange {
        width: 110px;
        position: absolute;
        bottom: 15px;
        right: 0px;

        @media (max-width: 1076px) {
          right: 10px;
        }

        @media (max-width: $sm) {
          right: -20%;
        }
        @media (max-width: 550px) {
          right: 0px;
        }

        @media (max-width: $xsm) {
          right: 0%;
        }
      }
    }

    h2 {
      color: #00054c;
      font-size: 1.8rem;
      margin-bottom: 10px;
    }
    .line-color {
      display: flex;
      justify-content: space-between;
      width: 140px;
      margin-bottom: 20px;
      .color-1 {
        width: 18px;
        height: 5px;
        background-color: #de3d00;
      }
      .color-2 {
        width: 18px;
        height: 5px;
        background-color: #00a1de;
      }
    }
    .content-text {
      padding-top: 5px;

      @media (max-width: 550px) {
        padding-bottom: 30px;
      }

      p {
        font-size: 16px;
      }
    }
    .image-made {
      width: 100%;
      border-radius: 15px;
      // @media (min-width: 1026) {
      //   width: 300px;
      // }
      // @media (max-width: $sm) {
      //   width: 100%;
      // }
      // @media (max-width: $xsm) {
      //   width: 300px;
      // }
    }
  }
}
