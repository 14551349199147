$footer: #00A1DE;
$white: #fffefa;
$beige: #f8f3e7;
$or: #bc8e21;
$or-hover: #b68716;
$or-active: #ad7d08;
$black: #151515;
$grey: #808080;
$grey-2: #9d9d9d;
$grey-1: #f5f5f5;
$grey1-hover: #eaeaea;
$red: #e95f5f;
$blue: #65b2ef;
$dark-blue: #0d3a73;
$orange: #DE3D00;

$xsm: 414px;
$sm: 768px;
$md: 1024px;
$lm: 1440px;
$xlm: 1920px;



$family: "Bellota Text";
